.ButtonSmall {
    font-size: 2vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    color: #6B6B6B;

    display: inline-block;
    box-sizing: content-box;
    
    margin-top: 0.5vh;
    margin-bottom: -1.5vh;

    text-decoration: underline;
}