/* Game Board */
/* * {
    outline: 1px solid red;
} */

.ScreenShakeWrapper {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.Board {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 70vh;
    height: 70vh;
    border: 0.5vh solid #CBCBCB;
    background-color: #CBCBCB;
    border-radius: 1vh;
    overflow: visible;
    margin: 2vh;
    pointer-events: visible;
}

.BoardContainer {
    position: relative;
    display: flex;
    align-items: center;
    pointer-events: none;
}

.Cell {
    width: 8.75vh;
    height: 8.75vh;
    z-index: 0;
}

.CellSkin {
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.CanMove {
    pointer-events: none;
    width: 100%;
    height: 100%;
}

/* Chess Pieces */
.Piece {
    display: flex;
    pointer-events: none;
    position: absolute;
    width: 12.5%;
    height: 12.5%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    will-change: 'transform';
}

.PieceImage {
    bottom: 30%;
    position: absolute;
    width: 200%;
    height: 200%;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    transform-origin: 50% 100%;
} 

.PieceShadow {
    bottom: 30%;
    position: absolute;
    width: 200%;
    height: 200%;
    pointer-events: none;
    align-items: center;
    justify-content: center;
    transform-origin: 50% 100%;
    background-image: url('../../images/utils/bottom_shadow.svg'); 
} 

/* User Profiles */
.OpponentProfile {
    position: absolute;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: center;
    width: auto;
    /* height: 12.5vh; */
    height: 17.5vh;
    top: 1.5vh;
    left: 1.5vh;
    flex-grow: 1;
    overflow-x: hidden;
    pointer-events: none;
}

.OpponentInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: left;
    margin-left: 1vh;
    margin-top: 1vh;

    justify-content: flex-start;
}

.MyInfo {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    text-align: right;
    margin-right: 1vh;
    margin-bottom: 1vh;

    justify-content: flex-end;
}

.CountdownTimer {
    overflow: hidden;
    width: 100%;
    height: 0.5vh;
    
    background-color: #CBCBCB;
    border-radius: 1vh;
    top: 71vh;
    align-items: center;
    justify-content: center;
}

.EndTurnTimer {
    position: absolute;
    width: '100%';
    height: '100%';
    
    display: flex;
    font-family: 'Inter', sans-serif;
    font-weight: 200;

    text-align: center;
    font-size: 25vh;
    margin-bottom: 5vh;
    color: #6B6B6B;
}

.MyProfile {
    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;
    width: auto;
    flex-grow: 1; 
    overflow-x: hidden;
    pointer-events: none;
}

.Icon {
    height: 17.5vh;
    border-radius: 1vh;
    border: 0.25vh solid #6B6B6B;
    margin-left: 0.25vh;
    margin-right: 0.25vh;
    box-sizing: border-box;
}

.GameStatus {
    position: absolute;
    display: flex;
    bottom: 7.5vh;
}

/* Other UI */
/* 52.5 is 8.75 * 6 */
.Shop {
    display: flex;
    flex-direction: column;
    height: 26.25vh;
    width: 25vh;
    background-color: #D9D9D9;
    outline: 0.5vh solid #CBCBCB;
    border-radius: 1vh;
    pointer-events: visible;
}

.ShopTile {
    display: flex;
    flex-direction: row;
    height: 8.75vh;
    width: 100%;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.DraggedShopTile {
    position: absolute;
    height: 6.125vh;
    width: 25vh;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    pointer-events: none;
}

.ShopTileIcon {
    display: flex;
    height: 70%;
    width: 100%;
    margin-bottom: 1.5vh;
}

.History {
    display: flex;
    flex-direction: column;
    height: 70vh;
    width: 25vh;
    background-color: transparent;
    align-items: flex-end;
}

.PromotionDisplay {
    position: absolute;
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
}

.Promotion {
    display: flex;
    flex-wrap: wrap;
    height: 16.5vh;
    width: 16.5vh;
    border: 0.5vh solid #6B6B6B;
    border-radius: 1vh;
    background-color: #CBCBCB;
    align-items: center;
    justify-content: center;
    margin: 0.25vh;
    overflow: hidden;
}

.PromotionPiece {
    height: 100%;
    width: 100%;
    scale: 2;
    z-index: 1;
    pointer-events: none;
}

.PromotionText {
    font-size: 3.5vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    overflow: hidden;
    text-align: center;
    color: #CBCBCB;
}

.AimingGun {
    position: relative;
    display: flex;
    scale: 2;
}

/* Game Over Display */
.DarkBackground {
    position: absolute;    
    background-color: #6B6B6B;
    opacity: 0.6;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
}

.GameOverPanel {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 52.5vh;
    height: 26vh;
    left: calc('50% - 35vh');
    top: calc('50% - 24vh');
    opacity: 1;
    
    background-color: #D9D9D9;
    border: 0.5vh solid #6B6B6B;
    border-radius: 1vh;

    align-items: center;
    justify-content: center;
    text-align: center;
}

.StatusText {
    position: absolute;
    font-size: 8vh;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    overflow: hidden;

    color: #D9D9D9;
    text-shadow:
        -4px -4px 0 #6B6B6B,  
         4px -4px 0 #6B6B6B,
        -4px  4px 0 #6B6B6B,
         4px  4px 0 #6B6B6B;

    margin-bottom: 28vh;
    overflow: visible;
}

.InfoText {
    font-size: 2.75vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    overflow: hidden;

    margin: 0;

    color: #6B6B6B;
}

.RankText {
    font-size: 3vh;
    font-family: 'Inter', sans-serif;
    font-weight: 1000;
    overflow: hidden;
    margin-bottom: 0vh;

    color: #6B6B6B;
}

.RowSelection {
    display: flex;
    flex-direction: row;
    align-items: center; 
    justify-content: center;
}

.GameOverButton {
    font-size: 2.75vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    text-decoration: underline;

    margin-left: 1vh;
    margin-right: 1vh;

    color: #6B6B6B;
}

.CreditBar {
    background-color: #D9D9D9;
    height: 100%;
    width: 50%;

    border-radius: 0.5vh;
}

.CreditBarBackground {
    height: 1vh;
    width: 45vh;
    background-color: #6B6B6B;

    border: 0.5vh solid #6B6B6B;
    border-radius: 1vh;

    margin-bottom: 1.5vh;
}

.SettingsTitle {
    position: absolute;
    font-size: 8vh;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    overflow: hidden;

    color: #D9D9D9;
    text-shadow:
        -4px -4px 0 #6B6B6B,  
         4px -4px 0 #6B6B6B,
        -4px  4px 0 #6B6B6B,
         4px  4px 0 #6B6B6B;

    margin-bottom: 51vh;
    overflow: visible;
}

.SurrenderAndDraw {
    position: absolute;
    height: 15vh;
    width: 45vh;

    background-color: #D9D9D9;
    border: 0.5vh solid #6B6B6B;
    border-radius: 1vh;

    text-align: center;
}

.Settings {
    position: absolute;
    height: 30vh;
    width: 45vh;

    background-color: #D9D9D9;
    border: 0.5vh solid #6B6B6B;
    border-radius: 1vh;

    text-align: center;
}

.BigTitle {
    position: absolute;
    font-size: 8vh;
    font-family: 'Inter', sans-serif;
    font-weight: 600;
    overflow: hidden;

    color: #D9D9D9;
    text-shadow:
        -4px -4px 0 #6B6B6B,  
         4px -4px 0 #6B6B6B,
        -4px  4px 0 #6B6B6B,
         4px  4px 0 #6B6B6B;

    margin-bottom: 16.5vh;
    overflow: visible;
}

.OverlayUIButton {
    position: absolute;
    display: flex;
    width: 7vh;
    height: 6.5vh;
    justify-content: center;
    align-items: center;
}

.CountdownTimer {
    position: absolute;
    font-size: 3vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    overflow: hidden;

    color: #6B6B6B;
}

.SurrenderButton {
    width: 100%;
    height: 100%;
    background-image: url('../../images/utils/surrender.svg');

    background-color: #D9D9D9;
    border: 0.25vh solid #6B6B6B;
    border-radius: 1vh;
}

.SettingsButton {
    width: 100%;
    height: 100%;
    background-image: url('../../images/utils/settings.svg');

    background-color: #D9D9D9;
    border: 0.25vh solid #6B6B6B;
    border-radius: 1vh;
}

.DrawButton {
    width: 100%;
    height: 100%;
    background-image: url('../../images/utils/draw.svg');

    background-color: #D9D9D9;
    border: 0.25vh solid #6B6B6B;
    border-radius: 1vh;
}

.HorizontalDrawPanel {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.MySpeechBubble {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #f0f0f0;
    border: 0.25vh solid #6B6B6B;
    border-radius: 1vh;
    padding: 1vh;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    justify-content: center;
    align-items: center;

    height: 6vh;
    width: 30vh;
    /* bottom: 15.5vh; */
    bottom: 20.5vh;
    right: 1.5vh;
    transform-origin: bottom right;
}
  
.MySpeechBubble:after {
    content: '';
    position: absolute;
    bottom: -15px; /* Position of the triangle */
    right: 10px; /* Adjust to move the triangle left or right */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 15px solid #f0f0f0; /* Color and size of the triangle */
    border-top-color: #6B6B6B; /* Border color for the top side of the triangle */
}

.OpponentSpeechBubble {
    display: flex;
    flex-direction: column;
    position: absolute;
    background: #f0f0f0;
    border: 0.25vh solid #6B6B6B;
    border-radius: 1vh;
    padding: 1vh;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    justify-content: center;
    align-items: center;

    height: 6vh;
    width: 30vh;
    top: 20.5vh;
    left: 1.5vh;
    transform-origin: top left;
}
  
.OpponentSpeechBubble:after {
    content: '';
    position: absolute;
    top: -15px; /* Position of the triangle */
    left: 10px; /* Adjust to move the triangle left or right */
    width: 0;
    height: 0;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 15px solid #6B6B6B; /* Color and size of the triangle */
    border-bottom-color: #6B6B6B; /* Border color for the top side of the triangle */
}

.VolumnSlider {
    width: 80%;
    height: 0.5vh;

    background: #6B6B6B;
    /* -webkit-appearance: none; */
    border-radius: 1vh;
}

.VolumnSlider::-webkit-slider-thumb {
    /* -webkit-appearance: none; */
    appearance: none;
    width: 1vh; 
    height: 2vh; 
    background: gray;
    cursor: pointer;
    border-radius: 1vh; /* Circular shape */
}

.EmoteButton {
    width: 5vh;
    height: 5vh;
    border: 0.25vh solid #6B6B6B;
    border-radius: 0.5vh;
    background-color: #D9D9D9;
}