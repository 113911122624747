.TermsHolder {
    display: flex;
    flex-direction: row;
    padding-top: 2vh;
    align-items: center;
    width: 35vh;
}

.TermsLabel {
    font-size: 2.5vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    text-decoration: underline;
    padding-right: 3vh;

    color: #6B6B6B;
}

.TermsCheckbox {
    background-color: transparent;
    appearance: none;
    cursor: pointer;
    width: 3vh;
    height: 3vh;
    border: 0.25vh solid #6B6B6B; 
    border-radius: 50%; 
    position: relative; 
}

/* Custom checkbox appearance when checked */
.TermsCheckbox:checked::before {
    content: '';
    position: absolute;
    top: -10%;
    left: -10%;
    width: 120%;
    height: 120%;
    background-color: #6B6B6B; 
    border-radius: 50%; 
}

.Capcha {
    background-color: transparent;
    margin-top: 3vh;
    margin-bottom: 1.5vh;
    justify-content: center;
    align-items: center;
}
