@import '../fonts/fonts.css';

.Screen {
    overflow: hidden;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    background-color: transparent;

    align-items: center;
    justify-content: center;
}

.HeadTitle {
    font-size: 7.5vh;
    font-family: 'Inter', sans-serif;
    font-weight: 200;
    color: #6B6B6B;
    
    padding-bottom: 1vh;
}

.Subtitle {
    font-size: 2.25vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    color: #6B6B6B;
    transform-origin: center;
}

.SmallButton {
    font-size: 2.25vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    margin-top: 3.5vh;
    text-decoration: underline;

    color: #6B6B6B;
}

.Button {
    font-size: 3.5vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    margin-bottom: 2vh;
    text-decoration: underline;

    color: #6B6B6B;
}

.SmallButton {
    font-size: 2.5vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    text-decoration: underline;

    color: #6B6B6B;
}

.VeryLargeText {
    font-size: 6vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    overflow: hidden;

    color: #6B6B6B;
}

.LargeText {
    font-size: 3.5vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    overflow: hidden;

    color: #6B6B6B;
}

.NormalText {
    font-size: 3vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    overflow: hidden;

    color: #6B6B6B;
}

.SmallText {
    font-size: 2.5vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    overflow: hidden;

    color: #6B6B6B;
}

.SmallerText {
    font-size: 2vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    overflow: hidden;

    color: #6B6B6B;
}

.BackButton {
    position: absolute;
    bottom: 5vh;
    left: 5vh;
    font-size: 3.5vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    color: #6B6B6B;
    text-decoration: underline;
}

.InputField {
    font-size: 3vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    
    width: 35vh;
    background-color: transparent;
    border: none;
    outline: none;
    padding-top: 2vh;
    color: #6B6B6B;

    border-bottom: 2px solid #6B6B6B; 
}

/* Autofill styles for webkit browsers (Chrome, Safari, etc.) */
.InputField:-webkit-autofill,
.InputField:-webkit-autofill:hover,
.InputField:-webkit-autofill:focus,
.InputField:-webkit-autofill:active {
    -webkit-box-shadow: 0 0 0px 1000px transparent inset !important;
    -webkit-text-fill-color: #6B6B6B !important;
    box-shadow: 0 0 0px 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
}

/* Autofill styles for Mozilla Firefox */
.InputField:-moz-autofill,
.InputField:-moz-autofill:hover,
.InputField:-moz-autofill:focus,
.InputField:-moz-autofill:active {
    box-shadow: 0 0 0px 1000px transparent inset !important;
    transition: background-color 5000s ease-in-out 0s !important;
}

.HorizontalFiller {
    width: 100%;
}

.Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.AlertMessage {
    position: absolute;
    top: 1vh;
    padding: 1vh;
    justify-content: center;
    text-align: center;
    align-items: center;
    border: 0.25vh solid #6B6B6B;
    border-radius: 1vh;
    display: flex;
    left: 50%;
    top: 5vh;
    background-color: #D9D9D9;
}


/* Profile */
/* ========================= */

.Username {
    font-size: 3.5vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    color: #6B6B6B;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 1vh;
}

.Profile {
    display: flex;
    flex-direction: column;
    margin-top: -2vh;
    margin-bottom: 3vh;
    align-items: center;
    justify-content: center;
}

.Icon {
    height: 10vh;
    border-radius: 5px;
    border: 1vh solid #6B6B6B;
    box-sizing: border-box;
}

.Icon img {
    display: block;
    height: 100%;
}

.Info {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: right;
    margin-left: 1vw;
}