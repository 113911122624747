
/* Main Menu */
/* ========================= */

::selection {
    background: #c2c2c2; 
}

.TextSmall {
    font-size: 2.5vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    color: #6B6B6B;

    display: inline-block;
    box-sizing: content-box;
}

.ActualTextSmall {
    display: inline-block;
}