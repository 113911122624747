.ModeScroll {
    display: flex;
    flex-direction: row;
    overflow: visible; 
    width: 100%;
    height: 64vh;
    padding-top: 5vh;
    padding-bottom: 5vh;
    justify-content: center;
    margin-bottom: 2vh;
    align-items: center;
}

.CardContainer {
    display: flex;
    position: relative;
    width: 32vh;
    height: 64vh;
    margin-left: 1vh;
    margin-right: 1vh;
    justify-content: center;
    align-items: center;
}

.Category {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 32vh;
    height: 64vh;
    border-radius: 1.25vh;
    background-color: #CBCBCB;
    justify-content: center;
    align-items: center;
    text-align: center;
    /* box-shadow: 0.2vh 0.4vh 0.2vh 0.2vh rgba(156, 156, 156, 0.5); */
}

.CardBorder {
    background-color: transparent;
    pointer-events: none;
    width: 92%;
    height: 96%;
    border-radius: 1.25vh;
    border: 0.35vh solid #9C9C9C;
    position: absolute;
}

.CategoryTitle {
    font-size: 3.5vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    color: #9C9C9C;
}

.CategoryIcon {
    width: 100%;
    padding-bottom: 4vh;
}

.CategoryDescription {
    font-size: 2.5vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    padding-top: 1vh;
    color: #9C9C9C;
    width: 90%;
}

.ConfirmText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-90deg);
    font-size: 9vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    color: #9C9C9C;
    /* text-decoration: underline; */
}

.front,
.back {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    position: absolute;
    backface-visibility: hidden;
    background-color: #CBCBCB;
    border-radius: 1.25vh;
}
