.SpinButtons {
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
}

.GachaMachine {
    display: flex;
    width: 75vh;
    margin-top: 12.5vh;

    align-items: center;
    justify-content: center;
}

.Tokens {
    position: absolute;
    display: flex;
    flex-direction: row;
    right: 3vh;
    top: 3.5vh;
    height: 6vh;
    width: 30vh;

    align-items: center;
    justify-content: center;
    text-align: end;
}

.MachineWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow-x: hidden; 
    pointer-events: none;
    transform-origin: 50% 100%;
}

.GachaBanner {
    display: flex;
    width: 140vh;
    height: 70vh;
    outline: 0.5vh solid #CBCBCB;
    margin-bottom: 10vh;
    overflow: hidden;
    position: relative;
}

.ScreenShakeWrapper {
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CapsuleOpenerWrapper {
    position: absolute;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    overflow: hidden;
}

.DarkBackground {
    position: absolute;
    background-color: #000000;
    opacity: 0.75;
    width: 100vw;
    height: 100vh;
    left: 0;
    right: 0;
    overflow: hidden;
}

.Capsule {
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 25vh;
    height: 25vh;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.CapsuleLayers {
    position: absolute;
    width: 100%;
    height: 100%;
}

.Spotlight {
    position: absolute;
    width: 125%;
    height: 125%;
}