.MyBoard {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    width: 70vh;
    height: 35vh;
    border: 1vh solid #CBCBCB;
    background-color: #CBCBCB;
    border-radius: 1vh;
    overflow: visible;
    margin: 3vh;

    pointer-events: visible;
}

.SkinContainer {
    display: flex;
    flex-direction: row;
    white-space: nowrap;

    width: 70vh;
    height: 9.75vh;

    outline: 1vh solid #CBCBCB;
    border-radius: 3px;

    overflow-y: hidden;
    overflow-x: scroll;
    
    pointer-events: visible;
}

.SkinIconImageBackground {
    box-sizing: border-box;
    height: 100%;
    aspect-ratio: 1;
}

.SkinIconImage {
    height: 90%; 
    border-radius: 1vh;
    height: 100%;
    width: 100%;
    scale: 2.2;
    pointer-events: none;
    z-index: 100;
}

::-webkit-scrollbar {
    width: 100%;
    height: 1vh; 
}
  
::-webkit-scrollbar-track {
    background: #CBCBCB;
}
  
::-webkit-scrollbar-thumb {
    background: #888;
}

.MyCell {
    width: calc(100% / 8);
    height: calc(100% / 4);
    z-index: 0;
}

.MyCellSkin {
    width: 100%;
    height: 100%;
    pointer-events: none;
}

.MyPiece {
    display: flex;
    pointer-events: none;
    position: absolute;
    width: calc(100% / 8);
    height: calc(100% / 4);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    will-change: 'transform';
    pointer-events: none;
}

.MyPieceShadow {
    bottom: 25%;
    position: absolute;
    width: 200%;
    height: 200%;
    pointer-events: none;
    transform-origin: 50% 100%;
    background-image: url('../../images/utils/bottom_shadow.svg'); 
} 

.MyPieceImage {
    bottom: 25%;
    position: absolute;
    width: 200%;
    height: 200%;
    pointer-events: none;
    transform-origin: 50% 100%;
}

.ButtonGroup {
    position: relative;
    width: 22.5vh;
    height: 35vh;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.DisplayPanel {
    display: flex;
    width: 22.5vh;
    height: 35vh;
    flex-direction: column;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    outline: 5px solid #CBCBCB;
    border-radius: 0.25vh;
    pointer-events: visible;
}

.DisplayPanelImage {
    margin-top: -1.75vh;
    margin-bottom: -2.5vh;
    width: 90%;
    aspect-ratio: 1;
}

.CustomizationButton {
    position: relative;
    text-align: right; 
    margin-bottom: 2vh;
    top: 0;
    right: 0;

    font-size: 3vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    text-decoration: underline;
    color: #6B6B6B;

    display: inline-block;
    box-sizing: content-box;
}

.BoardContainer {
    position: relative;
    display: flex;
    align-items: center;
}

.LoadOutContainer {
    display: flex;
    flex-direction: horizontal;
    width: 70vh;
    height: 6.75vh;
    align-items: center;
    justify-content: center;

    margin-top: 1vh;
}

.LoadoutButton {
    display: flex;
    width: 6.75vh;
    height: 6.75vh;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: #D9D9D9;
    outline: 0.5vh solid #CBCBCB;
    border-radius: 1vh;

    margin-left: 1vh;
    margin-right: 1vh;

    font-size: 3vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    text-decoration: underline;

    color: #6B6B6B;
}

.LayoutSettings {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.LayoutButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 16.5vh;

    font-size: 3vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    text-align: center;

    border: none;
    outline: none;
    padding-top: 2vh;
    color: #6B6B6B;

    margin-top: 0.1vh;
    margin-left: 1vh;
    margin-right: 1vh;

    border-bottom: 0.25vh solid #6B6B6B; 
}

.LayoutNameField {
    font-size: 3vh;
    font-family: 'Inter', sans-serif;
    font-weight: 300;
    text-align: center;

    width: 33vh;
    background-color: transparent;
    border: none;
    outline: none;
    padding-top: 2vh;
    color: #6B6B6B;

    margin-left: 1vh;
    margin-right: 1vh;

    border-bottom: 0.25vh solid #6B6B6B; 
}

.EquipmentSlot {
    display: flex;
    width: 22.5vh;
    height: 8.5vh;
    margin-top: 0.25vh;
    margin-bottom: 0.25vh;
    outline: 0.5vh solid #CBCBCB;
    border-radius: 0.25vh;
    overflow: hidden;
}

.EquipmentSlotColum {
    display: flex;
    flex-direction: column;
    height: 35vh;
    width: 8.5vh;
    margin-right: 0.5vh;
    pointer-events: visible;
}