.PanelContainer {
    display: flex;
    flex-direction: row;
    width: 150vh;
    height: 45vh;

    justify-content: center;
    align-items: center;

    margin-top: 6vh;
    margin-bottom: 10vh;
}

.InfoPanel {
    display: flex;
    flex-direction: column;

    justify-content: center;
    align-items: center;

    width: 40vh;
    height: 100%;

    margin-top: 4.5vh;
}

.HostPanel {
    display: flex;
    flex-direction: column;

    width: 35vh;
    height: 100%;
    /* border: 0.5vh solid #6B6B6B; */
    border-radius: 1vh;
    
    justify-content: center;
    align-items: center;

    margin: 6vh;
}

.LobbyIcon {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: auto;
    aspect-ratio: 1;
    border: 0.5vh solid #6B6B6B;
    border-radius: 1vh;
    
    justify-content: center;
    align-items: center;

    margin-bottom: 2.5vh;
}

.SelectionPanel {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
    text-align: center;
    margin-top: 1vh;
}

.SelectionCell {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.Circle {
    width: 3vh;
    height: 3vh;
    border: 0.4vh solid #6B6B6B;
    border-radius: 50%;
}
/* '#CBCBCB' : '#D9D9D9' */