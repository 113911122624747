.SettingFrame {
    position: absolute;
    display: flex;
    flex-direction: column;
    top: -1vh;
    right: 13.8vh;
    border-radius: 1vh;
    justify-content: center;
    text-align: center;
    align-items: center;
    background-color: #CBCBCB;
    border: 0.25vh solid #6B6B6B;
    padding: 2.5vh;
}

/* General styles for the range input */
input[type="range"] {
    -webkit-appearance: none;  /* Removes default styles for WebKit/Blink */
    appearance: none;
    width: 100%; /* Full width of the container */
    background: transparent; /* No background */
    cursor: pointer; /* Pointer cursor on hover */
}

/* Styles for the track */
input[type="range"]::-webkit-slider-runnable-track {
    background: #6B6B6B;
    height: 0.5vh; /* Thin track */
    border-radius: 1.5px;
}

input[type="range"]::-moz-range-track {
    background: #6B6B6B;
    height: 0.5vh;
    border-radius: 1.5px;
}

/* Styles for the thumb */
input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none; /* Removes default styles for WebKit/Blink */
    height: 2vh; /* Small circle for the thumb */
    width: 2vh;
    background: #6B6B6B;
    border-radius: 50%;
    margin-top: -0.75vh; /* Centers thumb on the track */
}

input[type="range"]::-moz-range-thumb {
    height: 2vh;
    width: 2vh;
    background: #6B6B6B;
    border-radius: 50%;
    border: none;
}