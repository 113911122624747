.StatusContainer {
    display: flex;
    flex-direction: horizontal;
    align-items: center;
    justify-content: center;
    margin-top: 2vh;
    width: 30vh;
}

.ReadyCircleOutline {
    display: flex;
    width: 5vh;          
    height: 5vh;
    aspect-ratio: 1;
    border-radius: 50%;   
    background-color: transparent;
    border: 2px solid #6B6B6B; 
    margin-left: 5vh;
    align-items: center;
    justify-content: center;
}

.ReadyCircleInner{
    width: 4.5vh;  
    height: 4.5vh;       
    aspect-ratio: 1;
    border-radius: 50%; 
    background-color: #6B6B6B;
    margin: auto;
}