
/* Main App */
/* ========================= */

.App {
    overflow: hidden;
    height: 100vh;
    background-color: #D9D9D9;
    user-select: none;
    touch-action: manipulation;
    zoom: 1;
}

html, body {
    overflow-y: hidden;
}